import * as React from 'react'
import { Button, Col, ListGroup, Row } from 'react-bootstrap'
import BaseLayout from '../components/base-layout'
import introVideo from "../assets/videos/TNNL Intro Video.mp4"
import { Link } from 'gatsby'

// The front page of the website.
// TODO: Add appropriate imagery. Make the content fill the page.
// TODO: Once content stretches over 1 window height, remove flex shrink/grow.
const IndexPage = () => {
  return (
    <BaseLayout pageTitle="Simple and secure tools for everyday tasks" description="TNNL is a collection of tools and automations designed to speed up your day-to-day tasks with no coding and no maintenance. Tasks such as merging and concatenating multiple spreadsheets can be done in seconds.">

      <Row style={{height:"100%"}}>
        <Col style={{textAlign:"center", margin:"32px"}}>
          <h1 className="display-1" id="home" style={{fontWeight:"400"}}><b>Merge and split tables, hash files <a href="/#available-tools" style={{color:"#957fef", textDecoration:"none"}}>&amp; more</a></b></h1>
          <p className="lead"><b>
            TNNL is built for people who use online micro-tools such as image converters and PDF extractors to help them complete their work faster.
            These micro-tools have be reimagined by TNNL to be easier to find, simpler to use and more secure than ever before.
          </b></p>
          <Button size="lg" variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} href="https://app.tnnl.co.uk"><b>Get started with TNNL</b></Button>
          <div>
            <Link href="/#about" style={{color:"#957fef", textDecoration:"none"}}>
              or check out a demonstration
            </Link>
          </div>
        </Col>
      </Row>

      <Row style={{textAlign:"center", marginTop:"32px", marginBottom:"32px"}}>
        <Col style={{margin:"auto"}}>
          <div className="embed-responsive embed-responsive-16by9">
            <video controls title="TNNL Introduction" id="about" width="100%">
              <source src={introVideo} type="video/mp4"/>
              <p>
                Video playback is not supported by your browser! The video in this position describes the features of TNNL with a demonstration of one of the available tools which can merge spreadsheets together.
              </p>
            </video>
          </div>
        </Col>
      </Row>

      <Row style={{textAlign:"center", marginTop:"32px", marginBottom:"0px"}}>
        <Col style={{margin:"auto"}}>
          <h2 className="display-2" id="benefits"><b>Benefits</b></h2>
        </Col>
      </Row>
      
      <Row xs={1} md={3} style={{textAlign:"center", marginTop:"32px", marginBottom:"0px"}}>
        <Col style={{margin:"auto", marginTop:"0", marginBottom: "32px"}}>
          <h3 className="display-3"><b>No development 💻</b></h3>
          <p className="lead" style={{textAlign:"justify"}}>
            TNNL has developed a suite of tools to make your day-to-day tasks easier to complete. 
            These tools are <b>built and maintained by professional developers</b> who ensure that you have the best experience possible.
            That means that <b>you do not need to know how to code</b> as everything you need to use these tools effectively is handled by TNNL.
          </p>
        </Col>
        <Col style={{margin:"auto", marginTop:"0", marginBottom: "32px"}}>
          <h3 className="display-3"><b>Secure by design 🔒</b></h3>
          <p className="lead" style={{textAlign:"justify"}}>
            Every TNNL tool is run entirely from the cloud in a temporary container that is created when you use the tool, and destroyed after you've finished using it.
            That means that <b>only you have access to your data</b>, none of it remains with us and you can be sure that no pesky hackers will have access to it!
          </p>
        </Col>
        <Col style={{margin:"auto", marginTop:"0", marginBottom: "32px"}}>
          <h3 className="display-3"><b>Consistent design 🖌</b></h3>
          <p className="lead" style={{textAlign:"justify"}}>
            TNNL tools are designed with the end-user (that's you!) in mind the entire time. 
            That means that <b>TNNL tools are incredibly simple to understand</b>, with no clunky or confusing UI to allow you to focus on the task at hand.
            Regardless of where or how you work, TNNL tools will feel like second nature to you.
          </p>
        </Col>
      </Row>

      <Row style={{textAlign:"center", marginBottom:"32px"}}>
        <Col style={{margin:"auto"}}>
          <Button size="lg" variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} href="https://app.tnnl.co.uk"><b>Intrigued? Give TNNL a try</b></Button>
        </Col>
      </Row>

      <Row style={{textAlign:"center", marginTop:"32px", marginBottom:"0px"}}>
        <Col style={{margin:"auto"}}>
          <h2 className="display-2" id="available-tools"><b>Available tools</b></h2>
        </Col>
      </Row>

      <Row xs={1} md={2} style={{marginTop:"32px", marginBottom:"32px"}}>
        <Col style={{margin:"auto"}}>
          <ListGroup variant="flush">
            <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
              <b>Merging multiple tables</b>
            </ListGroup.Item>
            <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
              <b>Splitting tables</b>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col style={{margin:"auto"}}>
          <ListGroup variant="flush">
            <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
              <b>Bulk file hashing</b>
            </ListGroup.Item>
            <ListGroup.Item className="lead" style={{background:"#f5efed"}}>
              <b>Appending multiple tables</b>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      
      <Row style={{textAlign:"center", marginTop:"16px", marginBottom:"32px"}}>
        <Col style={{margin:"auto"}}>
          <p className="lead"><b>... with more being added all the time! 🚀</b></p>
        </Col>
      </Row>

      <Row style={{textAlign:"center", marginTop:"0px", marginBottom:"32px"}}>
        <Col style={{margin:"auto"}}>
          <Button size="lg" variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} href="https://app.tnnl.co.uk"><b>Start saving time today!</b></Button>
        </Col>
      </Row>
      
    </BaseLayout>
  )
}

export default IndexPage